document.addEventListener('DOMContentLoaded', () => {
    initUserDevice();
});

function initUserDevice() {
    const isUserDevice = document.querySelector('#updateDevicesData');
    if (!isUserDevice) {
        return;
    }

    const userDevice = new UserDevice();
    userDevice.init();
}

class UserDevice {
    constructor() {
        this.isProcessing = false;
    }

    init() {
        setInterval(() => {
            if (this.isProcessing) {
                return;
            }

            this.isProcessing = true;

            const rows = document.querySelectorAll('table tr[data-id]');
            let ids = [];
            rows.forEach(row => {
                const id = row.getAttribute('data-id');
                ids.push(id);
            });

            if (ids.length > 0) {
                const url = document.querySelector('#updateDevicesData').value;
                if (!url) {
                    return;
                }
                this.postData(url, { ids: ids })
                    .then(payload => {
                        if (!payload || !payload.data) {
                            console.error('Chybějící nebo neplatný payload');
                            return;
                        }

                        let actualConsumption = 0;
                        Object.keys(payload.data).forEach((key) => {
                            const row = document.querySelector(`table tr[data-id="${key}"]`);
                            if (!row) {
                                console.warn(`Řádek s ID ${key} nenalezen`);
                                return;
                            }

                            Object.entries(payload.data[key]).forEach(([key, value]) => {
                                const cell = row.querySelector('td.' + key);
                                if (key == 'col-mode') {
                                    if (value === 'disabled') {
                                        const buttons = cell.querySelectorAll('.btn');
                                        buttons.forEach(btn => {
                                            btn.classList.add('disabled');
                                            btn.setAttribute('aria-disabled', 'true');
                                        });
                                    } else {
                                        const buttons = cell.querySelectorAll('.btn');
                                        buttons.forEach(btn => {
                                            btn.classList.remove('disabled');
                                            btn.setAttribute('aria-disabled', 'false');
                                        });
                                    }
                                } else if (cell && value) {
                                    cell.innerHTML = value;
                                } else if (key == 'col-actualConsumptionPlain') {
                                    actualConsumption += value ? value : 0;
                                }
                            });
                        });

                        $('.datagrid-row-columns-summary .col-actualConsumption').text(actualConsumption + ' W');

                    })
                    .catch((error) => {
                        console.error('Chyba při odesílání dat:', error);
                    })
                    .finally(() => {
                        this.isProcessing = false;
                    });
            } else {
                this.isProcessing = false;
            }
        }, 1000);
    }

    async postData(url, data) {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP chyba! status: ${response.status}`);
            }

            return response.json();
        } catch (error) {
            console.error('Chyba při odesílání POST požadavku:', error);
            throw error;
        }
    }
}
